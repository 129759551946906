import * as React from 'react';

interface EmptyStateProps {
    className?: string;
    message: string | React.JSX.Element;
}

export const EmptyState = ({className, message}: EmptyStateProps) => (
    <div className={`tw-flex tw-items-center tw-justify-center tw-w-full tw-text-gray-400 tw-h-[275px]${className ? ` ${className}` : ''}`}>
        {message}
    </div>
);
