import * as React from 'react';
import format from 'date-fns/format';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {faShoppingCart} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {zonedTimeToUtc} from 'date-fns-tz';

import './ItemCard.scss';
import ImpressionTracker from '../../analytics/impression-tracker/ImpressionTracker';
import {AutoRoShipmentItem} from '../../auto-reorders/AutoReorderClass';
import {CardItem} from '../item.class';
import {CheckBox} from '../../ui/forms/CheckBox/CheckBox';
import {ContextPosition} from '../../ui/ContextMenu/ContextMenu';
import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import {ItemCardAlert, ItemCardAlerts} from './ItemCardAlerts';
import {ItemCardContextMenu} from './ItemCardContextMenu';
import {ItemToAdd} from '../../order-items/order-items.class';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {Price} from '../../pricing/Price';
import {ProductImage} from '../../ui/ProductImage/ProductImage';
import {ShareLinkType} from '../../share-link/share-link.class';
import {Stepper, StepperError, stepperErrorInitialState} from '../../ui/forms/Stepper/Stepper';
import {TemplateHelpers} from '../../tools/template-helpers.class';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';
import {Badge} from '../../ui/Badges/Badge';
import Button, {ButtonVariant} from '../../ui/Buttons/Button';

export interface CardOptions {
    addToList?: boolean;
    addToOrder?: boolean;
    autoReorder?: boolean;
    canEdit?: boolean;
    categoryLink?: boolean;
    contextMenu?: boolean;
    featured?: boolean;
    generateBarcode?: boolean;
    hideAddToOrder?: boolean;
    hideCheckbox?: boolean;
    hideCheckboxMobile?: boolean;
    hideSchedule?: boolean;
    inventoryControls?: boolean;
    locationLink?: boolean;
    minVal?: number;
    nextShipment?: string;
    overrideAddButtonText?: string;
    overrideAddButtonVariant?: ButtonVariant;
    primaryActionReplace?: boolean;
    remove?: boolean;
    showBin?: boolean;
    showInventoryControls?: boolean;
    showLastPurchased?: boolean;
    showPrice?: boolean;
    showShareLink?: boolean;
    useAbsoluteImagePath?: boolean;
}

interface ItemCardProps {
    cardItem: CardItem;
    cardOptions: CardOptions;
    componentName: string;
    deleteAutoRoItemConfirm?: (autoRoShipmentItem: AutoRoShipmentItem, showModal: boolean) => void;
    editEnrollmentShow?: (itemNum: string) => void;
    e2e?: string;
    gaItemListName: GaItemListName;
    hidden: boolean;
    index: number;
    itemCardAlert?: ItemCardAlert;
    onEnrollAutoReorder?: (itemToAdd: ItemToAdd) => void;
    onInventoryControl?: (cardItem: CardItem) => void;
    onRemove: (itemNum: string) => void;
    onReplace: (oldItemNum: string, quantity: number, categoryPath: string, shipmentDate: string) => void;
    onStepperUpdate?: (itemNum: string, qty: number) => void;
    onStepperUpdateAllowInvalid?: boolean;
    orderNumber: string;
    recordItemCheckbox: (id: string, checked: boolean) => void;
    setShareLinkModal?: Dispatch<SetStateAction<{linkId: string; linkType: ShareLinkType; show: boolean}>>;
    toggleAutoRoShipmentItemStatusConfirm?: () => void;
    unitsOrdered: number;
    user: User;
}

export const ItemCard = ({
    cardItem,
    cardOptions,
    componentName,
    deleteAutoRoItemConfirm = () => null,
    editEnrollmentShow = () => null,
    e2e,
    gaItemListName,
    hidden,
    index,
    itemCardAlert,
    onEnrollAutoReorder,
    onInventoryControl,
    onRemove,
    onReplace,
    onStepperUpdate,
    onStepperUpdateAllowInvalid,
    orderNumber,
    recordItemCheckbox,
    setShareLinkModal,
    toggleAutoRoShipmentItemStatusConfirm = () => null,
    unitsOrdered = 1,
    user,
}: ItemCardProps) => {
    const [orderQty, setOrderQty] = useState(unitsOrdered);
    const [stepperError, setStepperError] = useState<StepperError>(stepperErrorInitialState);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const _cardOptions: CardOptions = {
        addToList: false,
        addToOrder: false,
        canEdit: false,
        contextMenu: false,
        featured: false,
        generateBarcode: false,
        hideAddToOrder: false,
        inventoryControls: false,
        minVal: 1,
        remove: false,
        showBin: true,
        showLastPurchased: false,
        showPrice: true,
        useAbsoluteImagePath: false,
        ...cardOptions,
    };

    /**
     * validate qty 0
     */
    const validateStepper = () => {
        if (orderQty === 0) {
            setStepperError({error: 'Enter valid qty', message: 'Set order quantity to 1 or greater'});
            return false;
        } else if (orderQty > cardItem.qtyLimit) {
            setStepperError({error: `Limit ${cardItem.qtyLimit}`, message: 'Order quantity limit exceeded'});
            return false;
        } else {
            setStepperError({message: '', error: ''});
            return true;
        }
    };

    useEffect(() => {
        if (_cardOptions.inventoryControls) {
            setOrderQty(unitsOrdered);
        }
    }, [unitsOrdered]);

    useEffect(() => {
        if (cardItem.checked) {
            validateStepper();
        } else {
            setStepperError({message: '', error: ''});
        }
    }, [recordItemCheckbox]);

    /**
     * Adds items to order
     * @param itemsToAdd - Items to add to the order
     * @private
     */
    const _addToOrder = (itemsToAdd: ItemToAdd[]) => {
        orderItemsWorkflow.addToOrderModal(itemsToAdd, componentName, true);
    };

    /**
     * Add item to order
     * @param cardItemParam
     * @param qty
     */
    const addItemToOrder = (cardItemParam: CardItem, qty: number) => {
        if (!cardItemParam) {
            return;
        }
        const itemsToAdd: ItemToAdd[] = [
            {
                gaEcommerce: {item_list_name: gaItemListName},
                item: cardItemParam.item || cardItemParam.id,
                unitsOrdered: qty,
            },
        ];
        _addToOrder(itemsToAdd);
        gaLegacyCustomEvent({
            eventAction: `add_to_order`,
            eventCategory: gaItemListName as any,
            eventLabel: cardItemParam.item || cardItemParam.id,
        });
    };

    /**
     * TBD
     */
    const addToOrderClick = () => {
        if (validateStepper()) {
            if (cardOptions.primaryActionReplace) {
                onReplace(cardItem.item || cardItem.id, orderQty, cardItem.categoryPath, cardOptions.nextShipment);
            } else {
                addItemToOrder(cardItem, orderQty);
            }
        }
    };

    /**
     * TBD
     * @param props
     * @constructor
     */
    const CreateBadge = (props: {comment: string; className?: string}): JSX.Element => (
        <Badge
            className={`tw-mt-2 tw-italic tw-text-sm tw-font-normal tw-leading-[1.3] text-reset ${props.className}`}
            createBadge={true}
            variant="light"
        >
            Comment: {props.comment}
        </Badge>
    );

    /**
     * Returns ItemCardContextMenu
     * @param position - Position to apply
     */
    const renderContextMenu = (position: ContextPosition) => (
        <ItemCardContextMenu
            addItemToOrder={addItemToOrder}
            cardItem={cardItem}
            cardOptions={cardOptions}
            componentName={componentName}
            deleteAutoRoItemConfirm={deleteAutoRoItemConfirm}
            editEnrollmentShow={editEnrollmentShow}
            index={index}
            onEnrollAutoReorder={onEnrollAutoReorder}
            onInventoryControl={onInventoryControl}
            onRemove={onRemove}
            onReplace={onReplace}
            orderQty={orderQty}
            position={position}
            setShareLinkModal={setShareLinkModal}
            toggleAutoRoShipmentItemStatusConfirm={toggleAutoRoShipmentItemStatusConfirm}
            user={user}
        />
    );

    /**
     * Returns Stepper with desired configuration
     */
    const renderStepper = () => (
        <Stepper
            className={stepperError?.error && `error`}
            disabled={cardItem.discontinued}
            maximum={cardItem.qtyLimit}
            minimum={_cardOptions.minVal}
            onError={(stepperErrorParam) => {
                setStepperError(stepperErrorParam);
            }}
            onUpdateAllowInvalid={onStepperUpdateAllowInvalid}
            onUpdate={(qty) => {
                if (onStepperUpdate) {
                    onStepperUpdate(cardItem.item || cardItem.id, qty);
                }
                setOrderQty(qty);
            }}
            value={orderQty}
        />
    );

    /**
     * Template
     */
    if (hidden || !cardItem) {
        return null;
    }
    return (
        <div id={`itemCard_${cardItem.item || cardItem.id}`}>
            <ImpressionTracker
                data={{
                    dimension16: orderNumber,
                    index,
                    item_id: cardItem.item || cardItem.id,
                    item_list_name: gaItemListName,
                }}
                trackClicks={false}
            >
                <div className="tw-mb-4 tw-block md:tw-hidden tw-relative tw-min-w-0 tw-bg-white tw-border tw-rounded-sm">
                    <ItemCardAlerts
                        cardItem={cardItem}
                        itemCardAlert={itemCardAlert}
                        nextShipment={_cardOptions.nextShipment}
                        onReplace={onReplace}
                        orderQty={orderQty}
                        stepperError={stepperError}
                    />
                    <div className="tw-relative tw-p-4">
                        {_cardOptions.contextMenu && renderContextMenu(`static`)}
                        {!cardOptions.hideCheckbox && (
                            <div className="tw-mb-2">
                                <div
                                    className={`tw-[position:_inherit] tw-left-4 tw-top-[calc(50%-16px)]${
                                        cardOptions.hideCheckboxMobile ? ' tw-hidden lg:!tw-block' : ''
                                    }`}
                                >
                                    <CheckBox
                                        alias={cardItem.item || cardItem.id}
                                        ariaLabel={`Checkbox 1 for ${cardItem.item || cardItem.id}`}
                                        name="ItemCheckBox"
                                        checked={!!cardItem.checked}
                                        className="tw-self-center"
                                        onChange={(e) => {
                                            recordItemCheckbox(cardItem.item || cardItem.id, e.target.checked);
                                            if (e.target.checked) {
                                                validateStepper();
                                            }
                                        }}
                                        type="checkbox"
                                        labelClass="pb-0"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="tw-flex tw-items-center">
                            <div className="tw-flex-grow">
                                <div className="tw-flex tw-flex-wrap">
                                    <div className="tw-w-full tw-relative">
                                        <div className="tw-flex tw-flex-wrap tw-items-center">
                                            <div className="tw-max-w-[83.33333%] tw-w-full tw-relative">
                                                {cardItem.bin && _cardOptions.showBin && (
                                                    <div className="tw-font-bold tw-mb-1">Location: {cardItem.bin.toString()}</div>
                                                )}
                                                {cardItem.qtyLimit && (
                                                    <div className="tw-mb-4 tw-font-bold">Max Order Qty: {cardItem.qtyLimit}</div>
                                                )}
                                                {_cardOptions.showLastPurchased && (
                                                    <div className="tw-mb-4 tw-font-bold">
                                                        Last Ordered:&nbsp;
                                                        {cardItem?.lastInvcDt
                                                            ? format(zonedTimeToUtc(cardItem.lastInvcDt, `America/Chicago`), `MM/dd/yyyy`)
                                                            : `Not Available`}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && cardItem?.active && (
                                                    <div className="tw-mb-4 tw-font-bold">
                                                        Next Shipment:&nbsp;
                                                        {_cardOptions.nextShipment}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && !cardItem?.active && (
                                                    <div className="tw-mb-4 tw-font-bold">No Scheduled Shipments</div>
                                                )}
                                                {cardItem?.binQty && (
                                                    <div className="tw-mb-4 tw-font-bold">Assortment Pkg Qty: {cardItem?.binQty}</div>
                                                )}
                                                {cardItem?.orderNumber && (
                                                    <div className="tw-font-bold">
                                                        Last Ordered: {TemplateHelpers.formatDate(cardItem?.orderDate)}
                                                    </div>
                                                )}
                                                {cardItem?.orderCount && (
                                                    <Badge
                                                        variant="light"
                                                        className="!tw-mb-4"
                                                    >
                                                        PURCHASES: {cardItem?.orderCount}
                                                    </Badge>
                                                )}
                                                <div className="tw-text-sm tw-leading-[1.3] tw-font-normal">{`#${
                                                    cardItem.prettyid || cardItem.prettyItem?.trim()
                                                }${cardItem.myItemNumber ? ` | YOUR #${cardItem.myItemNumber}` : ''}`}</div>
                                                <ImpressionTracker
                                                    as="span"
                                                    data={{
                                                        dimension16: orderNumber,
                                                        index,
                                                        item_id: cardItem.item || cardItem.id,
                                                        item_list_name: gaItemListName,
                                                    }}
                                                    trackImpressions={false}
                                                >
                                                    <a
                                                        className="tw-block"
                                                        href={`/item/${cardItem.item || cardItem.id}`}
                                                    >
                                                        {cardItem.shortdesc || cardItem.description || cardItem.name}
                                                    </a>
                                                </ImpressionTracker>
                                            </div>
                                            <div className="tw-max-w-[33.333333%] tw-w-full tw-relative">
                                                <ImpressionTracker
                                                    data={{
                                                        dimension16: orderNumber,
                                                        index,
                                                        item_id: cardItem.item || cardItem.id,
                                                        item_list_name: gaItemListName,
                                                    }}
                                                    trackImpressions={false}
                                                >
                                                    <a href={`/item/${cardItem.item || cardItem.id}`}>
                                                        <ProductImage
                                                            altText={cardItem.description}
                                                            className="tw-my-4"
                                                            size={75}
                                                            src={cardItem.imagePath}
                                                            useAbsoluteUrl={_cardOptions.useAbsoluteImagePath}
                                                        />
                                                    </a>
                                                </ImpressionTracker>
                                                {_cardOptions.inventoryControls && (
                                                    <div className="tw-whitespace-nowrap tw-mb-4">Reorder Qty: {cardItem.quantity}</div>
                                                )}
                                                {cardItem.comment && _cardOptions.inventoryControls && (
                                                    <CreateBadge
                                                        className="tw-mb-4 -tw-mt-2"
                                                        comment={cardItem.comment}
                                                    />
                                                )}
                                            </div>
                                            <div className="tw-max-w-[66.666667%] tw-w-full tw-relative tw-text-right">
                                                <div className="tw-grid tw-grid-cols-1">
                                                    <div className="tw-w-full tw-relative">
                                                        <div className="tw-text-right">
                                                            {cardItem?.pkgQty &&
                                                                `${cardItem?.binQty ? 'Reorder ' : ''}Pkg Qty: ${cardItem.pkgQty}`}
                                                            {cardItem?.pkgQty && cardItem?.units && ` | `}
                                                            {cardItem?.units && `Order Qty ${cardItem.units}`}
                                                        </div>
                                                        {cardItem?.qtyOrd && (
                                                            <div className="tw-text-right">Total Qty: {cardItem.qtyOrd}</div>
                                                        )}
                                                    </div>
                                                    <div className="tw-w-full tw-relative">
                                                        {cardItem.interval && !cardOptions.hideSchedule && (
                                                            <div>Schedule: {cardItem.interval}</div>
                                                        )}
                                                        {typeof cardItem?.active === `boolean` && !_cardOptions.showPrice && (
                                                            <div className="tw-text-xl tw-leading-[1.2] tw-font-bold">
                                                                {cardItem.active ? `Active` : `Inactive`}
                                                            </div>
                                                        )}
                                                        <div className="tw-text-xl tw-leading-[1.2] tw-font-bold tw-mt-0">
                                                            {_cardOptions.showPrice && (
                                                                <Price
                                                                    alias={componentName}
                                                                    doNotShowEachPrice={true}
                                                                    item={cardItem}
                                                                    user={user}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cardOptions.hideAddToOrder ? `tw-hidden` : `tw-w-full`}>
                                        <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-12 tw-m-0">
                                            <div className="!tw-pl-0 tw-pr-2.5 tw-w-full tw-col-span-1 tw-col-start-1 sm:tw-col-span-5 sm:tw-col-start-3 tw-flex tw-justify-end">
                                                {renderStepper()}
                                            </div>
                                            <Button
                                                className="tw-w-full tw-px-2.5 tw-col-span-1 sm:tw-col-span-5"
                                                disabled={cardItem.discontinued}
                                                onClick={addToOrderClick}
                                                size="sm"
                                                type="button"
                                                variant={cardOptions.overrideAddButtonVariant || `primary`}
                                            >
                                                {cardOptions.overrideAddButtonText || `Add to Order`}
                                            </Button>
                                        </div>
                                        <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-12">
                                            <div className="tw-w-full tw-relative tw-cols-start-1 tw-col-span-1 sm:tw-col-span-5 sm:tw-col-start-3">
                                                {stepperError && <div className="tw-text-red-500">{stepperError.error}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`tw-mb-4 tw-hidden md:tw-block tw-min-w-0 tw-bg-white tw-border tw-rounded-sm ${
                        _cardOptions.featured ? 'tw-hidden lg:tw-block' : 'lg:tw-hidden'
                    }`}
                >
                    <ItemCardAlerts
                        cardItem={cardItem}
                        itemCardAlert={itemCardAlert}
                        nextShipment={_cardOptions.nextShipment}
                        onReplace={onReplace}
                        orderQty={orderQty}
                        stepperError={stepperError}
                    />
                    <div className="tw-relative tw-p-4">
                        {_cardOptions.contextMenu && renderContextMenu(`static`)}
                        {!_cardOptions.hideCheckbox && !_cardOptions.featured ? (
                            <div
                                className={`tw-absolute tw-left-4 tw-top-[11px]${
                                    cardOptions.hideCheckboxMobile ? '  tw-hidden lg:!tw-block' : ''
                                }`}
                            >
                                <CheckBox
                                    alias={cardItem.item || cardItem.id}
                                    ariaLabel={`Checkbox 2 for ${cardItem.item || cardItem.id}`}
                                    name="ItemCheckBox"
                                    checked={!!cardItem.checked}
                                    className="tw-self-center"
                                    onChange={(e) => {
                                        recordItemCheckbox(cardItem.item || cardItem.id, e.target.checked);
                                        if (e.target.checked) {
                                            validateStepper();
                                        }
                                    }}
                                    type="checkbox"
                                    labelClass="pt-3"
                                />
                            </div>
                        ) : (
                            ``
                        )}
                        <div className="tw-flex">
                            {cardItem && (
                                <div className="tw-text-xl tw-font-normal tw-leading-[1.2] tw-flex-shrink tw-mr-4 tw-ml-0 tw-mb-0 tw-mt-12">
                                    <ImpressionTracker
                                        as="span"
                                        data={{
                                            dimension16: orderNumber,
                                            index,
                                            item_id: cardItem.item || cardItem.id,
                                            item_list_name: gaItemListName,
                                        }}
                                        trackImpressions={false}
                                    >
                                        <a href={`/item/${cardItem.item || cardItem.id}`}>
                                            <ProductImage
                                                altText={cardItem.description}
                                                className="tw-mr-4"
                                                size={75}
                                                src={cardItem.imagePath}
                                                useAbsoluteUrl={_cardOptions.useAbsoluteImagePath}
                                            />
                                        </a>
                                    </ImpressionTracker>
                                </div>
                            )}
                            <div className="tw-flex-grow">
                                <div className="tw-grid tw-grid-cols-1">
                                    <div className="tw-w-full tw-relative">
                                        <div className="tw-flex tw-flex-wrap tw-items-center">
                                            <div className="tw-max-w-[83.3333%] tw-w-full tw-relative">
                                                {cardItem.bin && (
                                                    <div className="tw-font-bold tw-mb-1">Location: {cardItem.bin.toString()}</div>
                                                )}
                                                {cardItem.qtyLimit && (
                                                    <div className="tw-mb-4 tw-font-bold">Max Order Qty: {cardItem.qtyLimit}</div>
                                                )}
                                                {_cardOptions.showLastPurchased && (
                                                    <div className="tw-mb-4 tw-font-bold">
                                                        Last Ordered:&nbsp;
                                                        {cardItem?.lastInvcDt
                                                            ? format(zonedTimeToUtc(cardItem.lastInvcDt, `America/Chicago`), `MM/dd/yyyy`)
                                                            : `Not Available`}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && cardItem?.active && (
                                                    <div className="tw-mb-4 tw-font-bold">
                                                        Next Shipment:&nbsp;
                                                        {_cardOptions.nextShipment}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && !cardItem?.active && (
                                                    <div className="tw-mb-4 tw-font-bold">No Scheduled Shipments</div>
                                                )}
                                                {cardItem?.binQty && (
                                                    <div className="tw-mb-4 tw-font-bold">Assortment Pkg Qty: {cardItem?.binQty}</div>
                                                )}
                                                {cardItem?.orderNumber && (
                                                    <div className="tw-font-bold">
                                                        Last Ordered: {TemplateHelpers.formatDate(cardItem?.orderDate)}
                                                        {cardItem?.orderNumber && (
                                                            <span>
                                                                <span className="tw-mx-2">|</span>
                                                                <a href={`/order-history/${cardItem.invoiceNumber}`}>
                                                                    #{cardItem.orderNumber}
                                                                </a>
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                                {cardItem?.orderCount && (
                                                    <Badge
                                                        variant="light"
                                                        className="!tw-mb-4"
                                                    >
                                                        PURCHASES: {cardItem?.orderCount}
                                                    </Badge>
                                                )}
                                                <div className="tw-text-sm tw-leading-[1.3] tw-font-normal">{`#${
                                                    cardItem.prettyid || cardItem.prettyItem?.trim()
                                                }${cardItem.myItemNumber ? ` | YOUR #${cardItem.myItemNumber}` : ''}`}</div>
                                                <ImpressionTracker
                                                    classes="tw-inline-block tw-mb-4 tw-max-w-[91.666667%] tw-w-full tw-relative tw-px-0"
                                                    data={{
                                                        dimension16: orderNumber,
                                                        index,
                                                        item_id: cardItem.item || cardItem.id,
                                                        item_list_name: gaItemListName,
                                                    }}
                                                    trackImpressions={false}
                                                >
                                                    <a href={`/item/${cardItem.item || cardItem.id}`}>
                                                        {cardItem.shortdesc || cardItem.description || cardItem.name}
                                                    </a>
                                                </ImpressionTracker>
                                                {_cardOptions.inventoryControls && <div>Reorder Qty: {cardItem.quantity}</div>}
                                                {cardItem.comment && _cardOptions.inventoryControls && (
                                                    <CreateBadge
                                                        className="tw-mb-4 -tw-mt-2"
                                                        comment={cardItem.comment}
                                                    />
                                                )}
                                            </div>
                                            <div className="tw-max-w-[50%] tw-w-full tw-relative tw-text-left">
                                                <div className="tw-grid tw-grid-cols-1">
                                                    <div className="tw-w-full tw-relative">
                                                        <div className="tw-text-left">
                                                            {cardItem?.pkgQty &&
                                                                `${cardItem?.binQty ? 'Reorder ' : ''}Pkg Qty: ${cardItem.pkgQty}`}
                                                            {cardItem?.pkgQty && cardItem?.units && ` | `}
                                                            {cardItem?.units && `Order Qty ${cardItem.units}`}
                                                        </div>
                                                        {cardItem?.qtyOrd && (
                                                            <div className="tw-text-left">Total Qty: {cardItem.qtyOrd}</div>
                                                        )}
                                                    </div>
                                                    <div className="tw-w-full tw-relative">
                                                        {cardItem.interval && !cardOptions.hideSchedule && (
                                                            <div className="tw-mb-2">Schedule: {cardItem.interval}</div>
                                                        )}
                                                        {_cardOptions.showPrice && (
                                                            <div className="tw-text-xl tw-leading-[1.2] tw-font-bold tw-mt-0 tw-mb-4">
                                                                <Price
                                                                    alias={componentName}
                                                                    doNotShowEachPrice={true}
                                                                    item={cardItem}
                                                                    user={user}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cardOptions.hideAddToOrder ? `tw-hidden` : `tw-grid tw-grid-cols-1`}>
                                        <div className="tw-grid lg:tw-items-center tw-h-full">
                                            <div>
                                                <div className="tw-grid tw-grid-cols-2 tw-h-full">
                                                    <div className="tw-w-full tw-px-2.5 tw-relative tw-flex tw-justify-end">
                                                        <div className="tw-flex tw-flex-col">
                                                            {renderStepper()}
                                                            {stepperError && <div className="tw-text-red-500">{stepperError.error}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="tw-w-full tw-px-2.5 tw-relative">
                                                        <Button
                                                            disabled={cardItem.discontinued}
                                                            className="tw-w-full"
                                                            onClick={addToOrderClick}
                                                            size="sm"
                                                            type="button"
                                                            variant={cardOptions.overrideAddButtonVariant || `primary`}
                                                        >
                                                            {cardOptions.overrideAddButtonText || `Add to Order`}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {typeof cardItem?.active === `boolean` && !_cardOptions.showPrice && (
                                        <div className="tw-w-full tw-text-xl tw-font-bold tw-leading-[1.2]">
                                            {cardItem.active ? `Active` : `Inactive`}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`tw-relative tw-min-w-0 tw-border tw-bg-white tw-rounded-sm tw-mb-4${
                        _cardOptions.featured ? ' tw-hidden' : ' tw-hidden lg:tw-block'
                    }`}
                >
                    <ItemCardAlerts
                        cardItem={cardItem}
                        itemCardAlert={itemCardAlert}
                        nextShipment={_cardOptions.nextShipment}
                        onReplace={onReplace}
                        orderQty={orderQty}
                        stepperError={stepperError}
                    />
                    <div className="tw-relative tw-p-4">
                        {!cardOptions.hideCheckbox && (
                            <div className="tw-absolute tw-left-4 tw-top-[calc(50%-16px)] tw-text-center">
                                <CheckBox
                                    alias={cardItem.item || cardItem.id}
                                    ariaLabel={`Checkbox 3 for ${cardItem.item || cardItem.id}`}
                                    name="ItemCheckBox"
                                    checked={!!cardItem.checked}
                                    className="tw-self-center"
                                    onChange={(e) => {
                                        recordItemCheckbox(cardItem.item || cardItem.id, e.target.checked);
                                        if (e.target.checked) {
                                            validateStepper();
                                        }
                                    }}
                                    type="checkbox"
                                    labelClass="pt-3"
                                />
                            </div>
                        )}
                        <div className="tw-flex tw-items-center">
                            {cardItem && (
                                <div
                                    className={`tw-text-xl tw-font-normal tw-leading-[1.2] tw-flex-shrink-0 tw-mr-4${
                                        cardOptions.hideCheckbox ? ` tw-ml-0` : ` tw-ml-14`
                                    } tw-mb-0`}
                                >
                                    <ImpressionTracker
                                        as="span"
                                        data={{
                                            dimension16: orderNumber,
                                            index,
                                            item_id: cardItem.item || cardItem.id,
                                            item_list_name: gaItemListName,
                                        }}
                                        trackImpressions={false}
                                    >
                                        <a href={`/item/${cardItem.item || cardItem.id}`}>
                                            <ProductImage
                                                className="tw-mr-4"
                                                size={75}
                                                src={cardItem.imagePath}
                                                altText={cardItem.description}
                                                useAbsoluteUrl={_cardOptions.useAbsoluteImagePath}
                                            />
                                        </a>
                                    </ImpressionTracker>
                                </div>
                            )}
                            <div className="tw-flex-grow">
                                <div
                                    className={`tw-grid tw-items-center${
                                        cardOptions.hideAddToOrder ? ' tw-grid-cols-4' : ' tw-grid-cols-12'
                                    }`}
                                >
                                    <div
                                        className={`tw-relative tw-w-full${
                                            cardOptions.hideAddToOrder ? ' tw-col-span-3' : ' tw-col-span-8'
                                        }`}
                                    >
                                        <div className="tw-grid tw-grid-cols-2 tw-items-center">
                                            <div className="tw-w-full tw-relative">
                                                {cardItem?.orderNumber && (
                                                    <div className="tw-font-bold">
                                                        Last Ordered: {TemplateHelpers.formatDate(cardItem?.orderDate)}
                                                        {cardItem?.orderNumber && (
                                                            <span>
                                                                <span className="tw-mx-2">|</span>
                                                                <a href={`/order-history/${cardItem.invoiceNumber}`}>
                                                                    #{cardItem.orderNumber}
                                                                </a>
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                                {cardItem?.orderCount && (
                                                    <Badge
                                                        variant="light"
                                                        className="!tw-mb-4"
                                                    >
                                                        PURCHASES: {cardItem?.orderCount}
                                                    </Badge>
                                                )}
                                                {cardItem.bin && (
                                                    <div className="tw-font-bold tw-mb-1">Location: {cardItem.bin.toString()}</div>
                                                )}
                                                {cardItem.qtyLimit && (
                                                    <div className="tw-mb-4 tw-font-bold">Max Order Qty: {cardItem.qtyLimit}</div>
                                                )}
                                                {_cardOptions.showLastPurchased && (
                                                    <div className="tw-mb-4 tw-font-bold">
                                                        Last Ordered:&nbsp;
                                                        {cardItem?.lastInvcDt
                                                            ? format(zonedTimeToUtc(cardItem.lastInvcDt, `America/Chicago`), `MM/dd/yyyy`)
                                                            : `Not Available`}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && cardItem?.active && (
                                                    <div className="tw-mb-4 tw-font-bold">
                                                        Next Shipment:&nbsp;
                                                        {_cardOptions.nextShipment}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && !cardItem?.active && (
                                                    <div className="tw-mb-4 tw-font-bold">No Scheduled Shipments</div>
                                                )}
                                                {cardItem?.binQty && (
                                                    <div className="tw-mb-4 tw-font-bold">Assortment Pkg Qty: {cardItem?.binQty}</div>
                                                )}
                                                <div className="tw-text-sm tw-font-normal tw-leading-[1.3]">{`#${
                                                    cardItem.prettyid || cardItem.prettyItem?.trim()
                                                }${cardItem.myItemNumber ? ` | YOUR #${cardItem.myItemNumber}` : ''}`}</div>
                                                <ImpressionTracker
                                                    classes="tw-inline-block tw-mb-2 lg:tw-mb-0 tw-max-w-[83.3333%] sm:tw-max-w-[91.66667%] tw-w-full tw-px-0"
                                                    data={{
                                                        dimension16: orderNumber,
                                                        index,
                                                        item_id: cardItem.item || cardItem.id,
                                                        item_list_name: gaItemListName,
                                                    }}
                                                    trackImpressions={false}
                                                >
                                                    <a href={`/item/${cardItem.item || cardItem.id}`}>
                                                        {cardItem.shortdesc || cardItem.description || cardItem.name}
                                                    </a>
                                                </ImpressionTracker>
                                                {cardItem.comment && _cardOptions.inventoryControls && (
                                                    <CreateBadge comment={cardItem.comment} />
                                                )}
                                            </div>
                                            <div className="tw-w-full tw-relative tw-text-right">
                                                <div
                                                    className={`tw-grid lg:tw-items-center${
                                                        _cardOptions.inventoryControls || cardItem?.qtyOrd
                                                            ? ' tw-grid-cols-3'
                                                            : ' tw-grid-cols-2'
                                                    }`}
                                                >
                                                    <div className={`tw-w-full tw-relative`}>
                                                        <div className="tw-text-right">
                                                            {cardItem?.pkgQty &&
                                                                `${cardItem?.binQty ? 'Reorder ' : ''}Pkg Qty: ${cardItem.pkgQty}`}
                                                            {cardItem?.pkgQty && cardItem?.units && ` | `}
                                                            {cardItem?.units && `Order Qty ${cardItem.units}`}
                                                        </div>
                                                    </div>
                                                    {cardItem?.qtyOrd && (
                                                        <div className="tw-w-full tw-relative">Total Qty: {cardItem.qtyOrd}</div>
                                                    )}
                                                    <div className={`tw-w-full tw-relative`}>
                                                        {cardItem.interval && !cardOptions.hideSchedule && (
                                                            <div>Schedule: {cardItem.interval}</div>
                                                        )}
                                                        {_cardOptions.showPrice && (
                                                            <div className={`tw-my-0`}>
                                                                <Price
                                                                    alias={componentName}
                                                                    doNotShowEachPrice={true}
                                                                    item={cardItem}
                                                                    user={user}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {_cardOptions.inventoryControls && (
                                                        <div className="tw-w-full tw-relative">
                                                            <div>Reorder Qty: {cardItem.quantity}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cardOptions.hideAddToOrder ? `tw-hidden` : `tw-col-span-3`}>
                                        <div className="tw-grid tw-grid-cols-1 tw-items-center tw-h-full">
                                            <div className="tw-w-full tw-relative">
                                                <div className="tw-grid tw-grid-cols-10 tw-items-center tw-justify-end tw-h-full">
                                                    <div className="tw-col-span-7 tw-flex tw-justify-end">
                                                        <div className="tw-flex tw-flex-col">
                                                            {renderStepper()}
                                                            {stepperError?.error && (
                                                                <div className="tw-text-red-500 -tw-mb-6">{stepperError.error}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="tw-col-span-3 tw-flex tw-justify-center">
                                                        <Button
                                                            disabled={cardItem.discontinued}
                                                            onClick={addToOrderClick}
                                                            e2e={e2e}
                                                            size="sm"
                                                            type="button"
                                                            variant="primary"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faShoppingCart}
                                                                className="tw-text-white"
                                                            />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {typeof cardItem?.active === `boolean` && !_cardOptions.showPrice && (
                                        <div className="tw-max-w-[16.666667%] tw-mb-2 tw-font-normal tw-leading-[1.2] tw-justify-self-center tw-text-xl font-weight-bold mb-0 text-center">
                                            {cardItem.active ? `Active` : `Inactive`}
                                        </div>
                                    )}
                                    <div className="tw-col-span-1">{_cardOptions.contextMenu && renderContextMenu(`center`)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ImpressionTracker>
        </div>
    );
};
